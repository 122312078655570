import { ThemeOptions } from "@mui/material/styles";

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
      containedPrimary: {
        color: "white",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        border: "1px solid rgba(0, 0, 0, 0.08)",
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: "h4",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        lineHeight: 1,
        height: "1em",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
    },
  },
};

export default components;
