import { colors, ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#f5f6f8",
    paper: colors.common.white,
  },
  primary: {
    main: "#00aeef",
  },
  secondary: {
    main: "#006de3",
    contrastText: "#ffffff",
  },
  error: {
    main: "#00aeef",
  },
  warning: {
    main: colors.red[500],
  },
  action: {
    active: "#F93549",
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
};

export default palette;
